import React, { useEffect, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { MdEdit, MdSave } from "react-icons/md";
import api from "views/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";

  

const columnHelper = createColumnHelper();

function TransactionTable({ tableData }) {
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [editRowId, setEditRowId] = useState(null);
  const [editDate, setEditDate] = useState("");

  const handleEditClick = (rowId, currentDate) => {
    setEditRowId(rowId);
    setEditDate(currentDate);
  };

  const [userRole, setRole] = useState("User")
  

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"))
    // setUserName(currentUser.name);
    setRole(currentUser.role);
    console.log(currentUser);
  }, []);

  const handleSaveClick = async (rowId) => {
    try {
      // Convert editDate to ISO format
      const formattedDate = new Date(editDate).toISOString();
  
     const result= await api.post(`/updateTransactionDate/${rowId}`, {
        newDate: formattedDate, // Send the date in the required format
      });
      
      setEditRowId(null);
      if(result.status==200){
        window.location.reload(); 
      }
    } catch (error) {
      console.error("Failed to update date", error);
    }
  };
  
  


  const columns = [
    columnHelper.accessor("Transaction_id", {
      id: "Transaction_id",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Transaction ID
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    
    columnHelper.accessor("TransactionDate", {
      id: "TransactionDate",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Transaction Date
        </p>
      ),
      cell: (info) => {
        const rowId = info.row.original.Transaction_id;
        // const currentDate = info.getValue();
        const currentDate = new Date(info.getValue()).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
        return editRowId === rowId ? (
          <div className="flex items-center space-x-2">
            <input
              type="date"
              value={editDate}
              onChange={(e) => setEditDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
            <button
              onClick={() => handleSaveClick(rowId)}
              className="text-sm font-bold text-green-600 dark:text-green-400"
            >
              Save
            </button>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <p className="text-sm text-navy-700 dark:text-white">
              {currentDate}
            </p>
            {userRole === "representative" && (
        <button
          onClick={() => handleEditClick(rowId, currentDate)}
          className="text-sm font-bold text-blue-600 dark:text-blue-400"
        >
          Edit
        </button>
      )}
          </div>
        );
      },
    }),
    
    columnHelper.accessor("Amount", {
      id: "Amount",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Amount
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          ₹ {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("Type", {
      id: "Type",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Type</p>
      ),
      cell: (info) => {
        const type = info.getValue();
        const bgColor =
          type === "Profit"
            ? "bg-green-500"
            : type === "Loss"
            ? "bg-red-500"
            : type === "Withdraw"
            ? "bg-yellow-500"
            : "bg-blue-500";
        return (
          <p className={`text-sm text-white ${bgColor} p-2 rounded-xl text-center`}>
            {type}
          </p>
        );
      },
    }),
  ];

  const [data, setData] = useState(() => [...tableData]);

  useEffect(() => {
    setData(tableData);
    paginate(1);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = table
    .getRowModel()
    .rows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Card extra="w-full h-full px-6 pb-6 overflow-x-auto">
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Transaction Table
          </div>
          <CardMenu />
        </div>
        <div className="mt-8 overflow-x-scroll">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted()
                          ? header.column.getIsSorted() === "asc"
                            ? " ↑"
                            : " ↓"
                          : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {currentRows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="mr-2 rounded bg-gray-200 px-3 py-1 text-gray-700"
          >
            Previous
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastRow >= table.getRowModel().rows.length}
            className="rounded bg-gray-200 px-3 py-1 text-gray-700"
          >
            Next
          </button>
        </div>
      </Card>
    </div>
  );
}

export default TransactionTable;
